import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div style={{ position: 'relative', paddingTop: 'max(60%,326px)', height: 0, width: '100%' }}>
        <iframe
          allow="clipboard-write"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
          allowFullScreen={true}
          style={{ position: 'absolute', border: 'none', width: '100%', height: '100%', left: 0, right: 0, top: 0, bottom: 0 }}
          src="https://e.issuu.com/embed.html?d=2021_company_profile&u=gbkarchitects"
        ></iframe>
      </div>
    </div>
  );
}

export default App;
